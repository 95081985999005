/* PopupComponent.css */
.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.popup.open {
  display: flex;
}

.popup-content {
  background: lightgrey;
  padding: 20px;
  height: 80%;
  width: 70%;
  border-radius: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
}

.close-btn:hover {
  color: red;
}

@media only screen and (max-width: 600px) {
  
  .popup-content {
    height: 70%;
    width: 90%;
  }
  
  .close-btn {
    font-size: 30px;
  }

}