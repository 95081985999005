* {
  margin: 0;
}
:root {
  --white: #ffffff;
  --red: #e40017;
  --lightgrey: #afb9c8;
}
html {
  scroll-behavior: smooth;
}
body {
  width: 100%;
  height: auto;
  background: var(--lightgrey);
  background: -webkit-linear-gradient(to right, #4452a0, #26d0ce);
  background: linear-gradient(to right, #4452a0, #26d0ce);
  font-family: Calibri, "Trebuchet MS", sans-serif;
}
body::-webkit-scrollbar {
  display: none;
}

.required {
  color: rgb(228, 99, 99);
}
/* .sticky {
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  transition-timing-function: ease-in;
  transition: all 1s;
} */
.submitBtn {
}
.resetBtn {
  margin-left: 20px;
}
.staffTitle {
  font-size: 1.3rem;
}
.staffQual {
  font-size: 1.1rem;
}
.staffCategory {
  font-size: 0.9rem;
  position: relative;
  bottom: 10;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* 
input[type="number"] {
  -moz-appearance: textfield;
} */
.cursive_heading {
  padding: 10px;
  /* font-style: italic; */
  font-family: "Roboto Slab", serif;
  text-align: center;
  font-weight: bolder;
  text-decoration: underline;
  letter-spacing: 0.2rem;
  color: var(--white);
}

.tiles {
  display: block;
  border: 2px solid white;
  background-color: var(--lightgrey);
  height: 100%;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
  border-radius: 50px;
  padding: 10px;
  box-shadow: 5px 10px 18px rgb(212, 208, 208);
  transition: transform 1s;
}
.tiles:hover {
  transform: scale(1.03);
}
.tiles hr {
  background-color: var(--white);
}

@keyframes example {
  0% {
    border-radius: 0px;
    box-shadow: inset 0 0 20px var(--white);
  }
  20% {
    border-radius: 40px;
    box-shadow: inset 0 0 20px red;
  }
  40% {
    border-radius: 0px;
    box-shadow: inset 0 0 20px rgb(238, 139, 26);
  }

  60% {
    border-radius: 40px;
    box-shadow: inset 0 0 20px rgb(233, 236, 19);
  }
  80% {
    border-radius: 0px;
    box-shadow: inset 0 0 20px rgb(38, 241, 20);
  }

  100% {
    border-radius: 40px;
    box-shadow: inset 0 0 20px var(--white);
  }
}

.important_text {
  color: var(--white);
}
h1 {
  font-weight: bolder;
  text-align: center;
  font-size: 3rem;
}
.background_color {
  padding: 30px;
  padding-top: 0;
}
.phone_valid {
  display: none;
  align-items: center;
}

.page_forms {
  height: auto;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  font-size: 1rem;
  font-weight: bold;
  padding: 30px;
}

/* Carousal */
.mySlides {
  display: none;
}
.mySlides img {
  vertical-align: middle;
  width: 100%;
  height: 60vh;
}

/* Slideshow container */
.slideshow-container {
  width: 100%;
  height: 60vh;
  position: relative;
  margin: auto;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* Know About Model English School */
.home_contents {
  overflow: none;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: #8e9eab;
  background: -webkit-linear-gradient(to right, #0063b2ff, #9cc3d5ff);
  background: linear-gradient(to right, #eef2f3, #8e9eab);
}
.home_contents .tiles {
  background-color: var(--lightgrey);
  border: 2px solid var(--white);
  font-family: "sans-serif";
  font-weight: bolder;
  width: 70%;
}

#home_mes_logo {
  height: 70px;
  width: 70px;
}
.home_header_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home_headers {
  text-decoration: underline;
  font-family: "Roboto Slab", serif;
  font-size: 2rem;
  text-align: center;
  padding: 10px 0;
}
.home_page_content {
  padding-right: 8%;
  padding-left: 8%;
}
.home_page_p {
  font-size: 20px;
  text-align: justify;
  margin: 0 auto;
  width: 100%;
  line-height: 1.8;
  margin: 15px 0;
  word-break: keep-all;
}

#principal_img {
  height: 300px;
  width: 300px;
  float: left;
  margin-right: 10px;
  object-fit: contain;
}

/* Apply Online & flipBoxes */
.apply_online .flip-box-front {
  background: #1488cc;
  background: -webkit-linear-gradient(to right, #1488cc, #2b32b2);
  background: linear-gradient(to right, #1488cc, #2b32b2);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}
.apply_online .col a {
  text-decoration: none;
  font-weight: bolder;
  color: var(--white);
}

.flip-box {
  background-color: #3a539b;
  width: 300px;
  height: 200px;
  border: 1px solid #f1f1f1;
  perspective: 1000px;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 2s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  color: #fff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-back {
  background-color: dodgerblue;
  color: var(--white);
  transform: rotateY(180deg);
  text-align: center;
  font-weight: bold;
  padding-top: 2rem;
}
.flip-box-back .btn {
  font-weight: bold;
  background-color: rgb(56, 129, 247);
  color: var(--white);
}
.flip-box-ver {
  background-color: #3a539b;
  width: 300px;
  height: 200px;
  border: 1px solid #f1f1f1;
  perspective: 1000px;
}

.flip-box-inner-ver {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 2s;
  transform-style: preserve-3d;
}

.flip-box-ver:hover .flip-box-inner-ver {
  transform: rotateX(180deg);
}

.flip-box-front-ver,
.flip-box-back-ver {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-back-ver {
  background-color: dodgerblue;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotateX(180deg);
}
.flip-box-back-ver .btn {
  font-weight: bold;
  background-color: rgb(56, 129, 247);
  color: var(--white);
}
/* home->facilities */
.facilities {
  background: url("./resources/images/others/computer_lab.webp") no-repeat
    center;
  background-size: cover;
  height: 400px;
  width: 100%;
}
.facilities h1 {
  color: var(--white);
}
.facility_boxes {
  display: flex;
  justify-content: space-evenly;
}
.facilities h5 {
  font-weight: bold;
}
.facilities p {
  text-align: justify;
  width: 90%;
}
.facilities .card {
  background-color: #dfd9d9;
  transition: transform 1s;
  margin: 2%;
  height: auto;
  font-size: 4rem;
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1%;
  border-radius: 12px;
  box-shadow: 0px 13px 10px -7px black;
  float: left;
}
.facilities .card:hover {
  transform: scale(1.08);
  background-color: var(--white);
}
/* Vision  Mission */

.mission_and_vision .tiles {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 70%;
}
.mission_and_vision .cursive_heading {
  color: #000;
}
.mission_content h6 {
  font-size: 1rem;
  margin: 0 auto;
  width: 80%;
  line-height: 1.5;
  text-align: justify;
  word-wrap: keep-all;
  white-space: normal;
}

/* Results */
.results {
  color: var(--white);
  height: auto;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
}
.result_data {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  color: var(--white);
}
.result_data .child_table {
  text-align: center;
}

/* Managing Commitee */
.managing_commitee {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.mc_card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 250px;
  height: 450px;
  margin: 20px;
  border: 2px solid var(--white);
  border-radius: 20px;
  color: var(--white);
}
.mc_card > img {
  height: 250px;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.mc_card:hover {
  box-shadow: 5px 10px 18px rgb(212, 208, 208);
}

.container {
  padding: 2px 16px;
}

/* Facility Page */
.facilities_page h1 {
  letter-spacing: 0.5rem;
}

.facilities_page .tiles {
  background-color: var(--lightgrey);
  border-radius: 20px;
  margin-right: auto;
  margin-left: auto;
}
.facilities_page .tiles hr {
  background-color: black;
}
.facilities_page .tiles:hover {
  transform: none;
}
.facilities_page .row {
  margin-bottom: 5%;
}
.fac_img {
  height: 200px;
  width: 600px;
}
.fac_desc {
  padding: 20px;
}
.fac_desc h4 {
  font-weight: bolder;
  text-align: center;
}
.fac_desc p {
  font-weight: bold;
  font-size: 18px;
}

/* Admission Page */
.admission_text {
  width: 70%;
  text-align: justify;
}

.admission li {
  color: black;
  font-weight: bold;
  font-size: 22px;
}

.admission .tiles {
  padding-top: 10px;
  border-radius: 30px;
}
.admission .tiles:hover {
  transform: none;
}
.isSameAddress {
  width: 18px;
  height: 18px;
}

.successModal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content */
.modal-content {
  background-color: var(--white);
  color: black;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  font-weight: bolder;
}
.page-body {
  max-width: 300px;
  margin: 10% auto;
}
.page-body .head {
  text-align: center;
}

.checkmark-circle {
  width: 150px;
  height: 150px;
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.checkmark-circle .background {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #1ab394;
  position: absolute;
}
.checkmark-circle .checkmark {
  border-radius: 5px;
}
.checkmark-circle .checkmark.draw:after {
  -webkit-animation-delay: 300ms;
  -moz-animation-delay: 300ms;
  animation-delay: 300ms;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-name: checkmark;
  -moz-animation-name: checkmark;
  animation-name: checkmark;
  -webkit-transform: scaleX(-1) rotate(135deg);
  -moz-transform: scaleX(-1) rotate(135deg);
  -ms-transform: scaleX(-1) rotate(135deg);
  -o-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.checkmark-circle .checkmark:after {
  opacity: 1;
  height: 75px;
  width: 37.5px;
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
  border-right: 15px solid var(--white);
  border-top: 15px solid var(--white);
  border-radius: 2.5px !important;
  content: "";
  left: 35px;
  top: 80px;
  position: absolute;
}

@-webkit-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}
@-moz-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}

/* The Close Button */
#close {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
}

#close:hover,
#close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
/* Rules page */
.rules {
  padding: 20px;
}
.rules li {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 20px;
}

.career .tiles {
  border-radius: 20px;
  padding: 30px;
}
.career .tiles:hover {
  transform: none;
}

.add_button i {
  color: var(--white);
}
.remove_field {
  text-decoration: none;
  font-size: 1rem;
  border-radius: 50%;
}
.remove_field i {
  color: red;
}

/* Feedback page */

.feedback .tiles {
  border-radius: 20px;
  padding: 30px;
}
.feedback .tiles:hover {
  transform: none;
}
.feedback label {
  color: black;
  font-weight: bold;
}

/* gallery */
.gallery {
  margin-top: -10px;
}
.gallery_body {
  margin-bottom: 50px;
  height: auto;
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.gallery .close {
  position: absolute;
  right: 0;
  top: -40px;
  color: white;
}
.img_card {
  margin-top: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  object-fit: cover;
  cursor: pointer;
  width: 100%;
  height: 90%;
  border-radius: 20px;
}
.img_card > img {
  height: 60%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.gallery_card {
  margin-top: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  object-fit: cover;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.gallery_card > img {
  height: 80%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.img_desc > p {
  font-size: 0.9rem;
}
.gallery_card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.3);
}

.img_card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
}

.img_desc {
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.img_desc > p {
  margin-bottom: 0;
}
.view_image_btn {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.fa-eye {
  font-size: 1.2rem;
}
.gallery .modal-content {
  width: 100%;
  object-fit: contain;
}
/* staff page */
.staff_page h2 {
  letter-spacing: 5px;
  text-decoration: underline;
  font-weight: bolder;
  color: var(--white);
}

/* Footer */
footer {
  width: 100%;
  height: 15%;
  text-align: center;
  bottom: 0;
}

footer i {
  font-size: 40px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}
footer i:hover {
  opacity: 0.7;
}
footer .fa-facebook-square {
  color: #3b5998;
}
footer .fa-instagram-square {
  color: #dd4b39;
}
/* Media Query */
@media only screen and (max-width: 600px) {
  .background_color {
    padding: 5px;
  }
  .sticky {
    position: relative;
  }
  .cursive_heading {
    padding-top: 10px;
    letter-spacing: 0.2rem;
  }
  .btn {
    font-size: 0.8rem;
  }
  .waves {
    height: 40px;
    min-height: 40px;
  }
  .content {
    height: 30vh;
  }

  h1 {
    font-size: 1.2rem;
  }
  .carousel-item > img {
    height: auto;
  }
  .mySlides img {
    vertical-align: middle;
    height: 25vh;
  }

  /* Slideshow container */
  .slideshow-container {
    height: 25vh;
  }

  .header p {
    font-size: 1.5rem;
    margin-left: 10px;
  }
  .header .tagline {
    font-size: 1rem;
    margin-top: -6%;
  }
  .header img {
    height: 60px;
    width: 60px;
    margin-left: 0;
  }

  .tiles {
    border-radius: 20px;
    width: 85%;
  }
  .tiles:hover {
    transform: scale(1.03);
  }
  .home_contents .tiles {
    width: 95%;
    letter-spacing: 1px;
  }
  .home_headers {
    font-size: 1.3rem;
    padding: 0;
  }
  .home_page_content {
    padding-right: 2%;
    padding-left: 2%;
  }
  .home_page_p {
    font-size: 0.9rem;
    line-height: 1.2;
    font-weight: normal;
    word-break: keep-all;
    word-spacing: inherit;
  }

  #principal_img {
    height: 120px;
    width: 120px;
  }

  #home_mes_logo {
    height: 30px;
    width: 30px;
  }
  .facilities h5 {
    font-size: large;
  }

  .notice-modal-content {
    padding-right: 8vw;
  }
  .notice-modal-content h1 {
    font-size: 1.5rem;
  }
  .notice-modal-content ul {
    margin-left: -5%;
    font-size: 1rem;
    text-align: justify;
  }

  .apply_online,
  .flip-box,
  .flip-box-ver,
  .flip-box-front,
  .flip-box-front-ver {
    height: 100px;
    font-size: 0.5rem;
  }
  .flip-box-back {
    padding: 5px;
  }
  .flip-box-back hr {
    display: none;
  }
  .flip-box-back h2,
  p {
    font-size: 0.5rem;
  }
  .flip-box-back .btn {
    font-size: 0.5rem;
  }

  .apply_online .flip-box-front {
    font-size: 0.7rem;
  }
  .mission_and_vision .tiles {
    margin-top: 20px;
    width: 85%;
    margin-bottom: 20px;
  }
  .mission_and_vision h1 {
    padding-top: 10px;
  }
  .mission_content h6 {
    font-weight: normal;
    font-size: 0.9rem;
  }

  .facilities {
    height: 200px;
    width: 100%;
    overflow: auto;
  }
  .facilities .card {
    padding: 1px;
    margin: 5px;
    font-size: 1rem;
    border-radius: 0px;
  }
  .facilities .card h5 {
    font-size: 0.4rem;
    font-weight: bold;
    word-break: keep-all;
  }
  .facilities_page h1 {
    letter-spacing: 0.2rem;
  }
  .facilities_page .tiles {
    width: 40%;
  }

  .fac_img {
    height: 90%;
    width: 90vw;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .facilities_page h4 {
    font-size: 1rem;
  }
  .fac_desc {
    padding: 5px;
  }

  .fac_desc p {
    font-size: 0.7rem;
  }

  .admission_text {
    width: 90%;
  }
  .admission li {
    font-size: 0.7rem;
  }

  .rules li {
    font-size: 1rem;
    margin-bottom: 10px;
    padding-right: 5px;
  }

  .field_wrapper {
    font-size: 0.7rem;
    width: 115%;
  }

  .page_forms {
    width: 98%;
    font-size: 0.9rem;
    overflow: auto;
    margin-bottom: 20px;
  }
  .feedback .tiles {
    border-radius: 10px;
  }
  .fa-eye {
    font-size: 0.8rem;
  }
  footer {
    bottom: 0;
  }
  footer h5,
  h6 {
    font-size: 0.6rem;
    padding: 0;
  }
  footer i {
    font-size: 20px;
  }

  .results {
    width: 80%;
    min-height: 50vh;
  }
  .result_data {
    position: relative;
    top: -200px;
    width: 100%;
    padding: 10px;
    font-size: 0.7rem;
    margin-top: -10%;
  }
  /* managing committee */
  .mc_card {
    width: 300px;
    height: auto;
  }
  .mc_card h6 {
    font-size: 0.9rem;
  }
  .mc_card > img {
    height: 300px;
    object-fit: cover;
  }
  .mc_card:hover {
    box-shadow: 5px 10px 18px rgb(212, 208, 208);
  }

  .container {
    padding: 2px 16px;
  }
  /* staff */
  .staff_page h2 {
    font-size: large;
    padding-left: 20px;
  }
  .img_card,
  .gallery_card {
    margin-left: 60px;
    margin-top: 10px;
  }

  .staffTitle {
    font-size: 1rem;
  }
  .staffQual {
    font-size: 0.9rem;
  }
  .staffCategory {
    font-size: 0.8rem;
  }
}

/* Test CSS */
