/* ImageSlideshow.css */
.slideshow-container {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  
  .slide {
    display: none;
    position: absolute;
    width: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  .slide img {
    vertical-align: middle;
    width: 100%;
    height: 85vh;
  }
  
  .slide.active {
    display: block;
    opacity: 1;
  }
  
  @media only screen and (max-width: 600px) {
  .slide img {
    /* width: 100%; */
    height: 50%;
  }
  }