:root {
  --white: #ffffff;
  --red: #e40017;
  --lightgrey: #afb9c8;
}
.glow-button {
  display: inline-block;
  align-items: center;
  padding: 5px;
  margin-right: 20px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #2ba805;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.glow-button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, #ffffff 0%, #2ba805 80%);
  opacity: 0;
  border-radius: 5px;
  animation: glow 1.5s infinite;
}

@keyframes glow {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.nav {
  background-color: #2f4a99;
  font-weight: bold;
  font-size: 0.9rem;
}
.nav-item:hover {
  background-color: rgb(98, 151, 29);
  color: "#fff";
}
.nav-item {
  color: #fff; /* Your custom hover text color */
}
.navbar-nav .nav-link.dropdown-toggle {
  color: #fff !important; /* Your custom text color */
}

.navbar-toggler-icon {
    background-color: #fff; /* Your custom color */
  }

.header {
  background-color: #82a1ff;
  padding: 3px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
}
.header img {
  height: 6rem;
  width: 6rem;
  margin-left: 40px;
}
.header a {
  text-decoration: none;
}
.header p {
  color: var(--white);
  font-weight: bolder;
  letter-spacing: 1.5px;
  font-size: 2.5rem;
  margin-left: 30px;
  /* font-family: "sans-serif"; */
  font-family: "Roboto Slab", serif;
}
.header .tagline {
  font-size: 1.5rem;
  font-weight: bolder;
  margin-top: -5%;
}

.name_logo {
  display: flex;
}
/* Media Query */
@media only screen and (max-width: 600px) {
  .background_color {
    padding: 5px;
  }
  .sticky {
    position: relative;
  }
  .cursive_heading {
    padding-top: 10px;
    letter-spacing: 0.2rem;
  }
  .btn {
    font-size: 0.8rem;
  }

  .content {
    height: 30vh;
  }

  h1 {
    font-size: 1.2rem;
  }

  .header p {
    font-size: 1.5rem;
    margin-left: 10px;
  }
  .header .tagline {
    font-size: 1rem;
    margin-top: -6%;
  }
  .header img {
    height: 60px;
    width: 60px;
    margin-left: 0;
  }
}
